import { capitalizeFirst } from ".";

// for object with it's properties:
export function Comparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) { // a will shift to left
      return -1;
    }if (b[orderBy] > a[orderBy]) { // b will shift to right
      return 1;
    } 
    return 0;
  }

  export function SortArr(arr){
    // insertion sort
    const len = arr.length;
    for(let i=1; i<len; i++){
        let temp = arr[i]; // 4
        let j = i-1;
        while(j>=0 && arr[j] > temp){
            arr[j+1] = arr[j];
            j--;
        }
        arr[j+1] = temp
    }
    return arr;
  }