import React from 'react'

const ClientLists = () => {
  return (
    <div className='container clientlist-container'>
        <h1> Coming Soon... </h1>
    </div>
  )
}

export default ClientLists