import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../containers/Heading";
import Input from "../../containers/Input";
import { Alert, Button, Checkbox } from "@mui/material";
import {
  levelOptions,
  locationOptions,
  statusCode,
  cuisineOptions,
  ChefFormDetails,
} from "../../mocks/constants";
import Loader from "../../containers/Loader";
import "../../styles/addChefForm.scss";
import { validateAddChefForm } from "../../utils/validation";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { setError, setLoading } from "../../redux/chefSlice";
import { useFetchInterceptor } from "../../hooks/useFetchInterceptor";
import Select from "../../containers/Select";
import { SelectedMenuContext } from "../../App";
import MultiSelect from "../../containers/MultiSelect";
import ChefForm from "../../containers/ChefForm";

const AddChef = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchApiInterceptors = useFetchInterceptor();
  const { setSelectedMenu } = useContext(SelectedMenuContext);
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const { chefs } = useSelector((state) => state.chef);
  const { token } = useSelector((state) => state.auth);
  const { error, loading } = useSelector((state) => state.chef);

  const [levelLocationSelect, setLevelLocationSelect] = useState({
    level: "",
    workLocation: [],
    cuisine: [],
  });

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: "" });

  // get the current url and change the page drawer heading accordingly:
  useEffect(() => {
    setSelectedMenu(1);
  }, [location]);

  // const handleSelect = (e) => {
  //   const { name, value } = e.target;
  //   setLevelLocationSelect({
  //     ...levelLocationSelect,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const data = new FormData(e.currentTarget);
  //   const chefDetails = {
  //     name: data.get("name"),
  //     phone: data.get("phone"),
  //     location: data.get("location"),
  //     workLocation: data.get("workLocation").split(","),
  //     experience: data.get("experience"),
  //     inJob: true,
  //     curSalary: data.get("curSalary"),
  //     expSalary: data.get("expSalary"),
  //     cuisine: data.get("cuisine").split(','),
  //     level: data.get("level"),
  //     openToPartTime: data.get("openToPartTime") === "on" ? true : false,
  //   };

  //   try {
  //     // validate the form values:
  //     if (Object.keys(validateAddChefForm(chefDetails)).length) {
  //       setErrors(validateAddChefForm(chefDetails));
  //       return;
  //     }
  //     dispatch(setLoading(true));
  //     setErrors({});

  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify({ ...chefDetails, user_id: user.user_id }),
  //       credentials: "include",
  //       headers: {
  //         Accept: "application/json",
  //       },
  //     };
  //     const result = await fetchApiInterceptors(
  //       `/api/addchef`,
  //       token,
  //       options
  //     );
  //     dispatch(setLoading(false));
  //     setAlert({
  //       ...alert,
  //       show: true,
  //       message: result?.message || "Please try again!",
  //     });
  //     if (result.success) {
  //       dispatch(setError(false));
  //       setLevelLocationSelect({
  //         level: "",
  //         workLocation: [],
  //       });
  //       formRef.current.reset();
  //     } else {
  //       dispatch(setError(true));
  //     }
  //   } catch (err) {
  //     console.log("error", err);
  //     dispatch(setError(true));
  //     dispatch(setLoading(false));
  //     setAlert({
  //       ...alert,
  //       show: true,
  //       message: `Something went wrong! (${statusCode.addchef})`,
  //     });
  //   }

  //   setTimeout(() => {
  //     setAlert({ ...alert, show: false, message: `` });
  //   }, 2000);
  // };

  // const formDetails = useMemo(() => {
  //   return ChefFormDetails(levelLocationSelect, handleSelect)
  // }, [levelLocationSelect]);

  // console.log('formDetails1', formDetails)

  useEffect(() => {
    if (!user.user_id) {
      return navigate("/");
    } else if (user.role != "1" && user.role != "2") {
      return navigate("/error", {
        state: { message: "Not authorized to view this page!" },
      });
    }
  }, [user]);

  // const renderFields = (formDetails) => {
  //   return formDetails.map((item, idx) => {
  //     switch (item.type) {
  //       case "text":
  //         return (
  //           <div className={`input_label form_f`} key={idx}>
  //             <label>{item.label}:</label>
  //             <div className="input-span">
  //               <Input
  //                 type="text"
  //                 name={item.name}
  //                 value={item.value}
  //                 placeholder={item.placeholder}
  //                 changeHandler={item.changeHandler}
  //                 classNames={
  //                   Object.keys(errors).includes(item.name)
  //                     ? "border-error"
  //                     : ""
  //                 }
  //               />
  //               {Object.keys(errors).length ? (
  //                 <span className="error">{errors[item.name]}</span>
  //               ) : null}
  //             </div>
  //           </div>
  //         );
  //       case "select":
  //         return (
  //           <div className={`select_label form_f`} key={idx}>
  //             <label htmlFor="multiple-select">{item.label}:</label>
  //             <div className="input-span">
  //               {item.name === "workLocation" || item.name === "cuisine" ? (
  //                 <MultiSelect
  //                   value={item.value || []}
  //                   error={Object.keys(errors).includes(item.name)}
  //                   label={item.label}
  //                   handleChange={item.changeHandler}
  //                   name={item.name}
  //                   options={item.options}
  //                   errorMess={errors[item.name]}
  //                 />
  //               ) : (
  //                 <Select
  //                   value={item.value}
  //                   error={Object.keys(errors).includes(item.name)}
  //                   label={item.label}
  //                   handleChange={item.changeHandler}
  //                   name={item.name}
  //                   options={item.options}
  //                   errorMess={errors[item.name]}
  //                 />
  //               )}
  //             </div>
  //           </div>
  //         );
  //       case "checkbox":
  //         return (
  //           <div className="checkbox_label form_f" key={idx}>
  //             <label>{item.label}</label>
  //             <Checkbox
  //               checked={item.value}
  //               name={item.name}
  //               onChange={item.changeHandler}
  //               inputProps={{ "aria-label": "controlled" }}
  //             />
  //           </div>
  //         );
  //     }
  //   });
  // };
  return (
    <div className="addchef_container">
      {alert.show && (
        <Alert
          className="alert-box"
          variant="filled"
          icon={error ? <ErrorIcon /> : <CheckIcon />}
          severity={error ? "error" : "success"}
        >
          {alert.message}
        </Alert>
      )}
      {/* <div className="form addchef_form"> */}
      <Heading component="h3" content={"Add Chef"} />
      <ChefForm formClass="addchef_form" setAlert={setAlert} />
      {/* <form ref={formRef} onSubmit={handleSubmit}>
          <Heading component="h3" content={"Add Chef"} />
          <div className="field_container"> {renderFields(formDetails)}</div>
          <Button
            type="submit"
            className="btn submit_btn"
            // onclick={handleSubmit}
          >
            {loading ? <Loader /> : "Submit"}
          </Button>
        </form> */}
      {/* </div> */}
      {/* {Object.keys(errors).length ? (
        <div className="error">
          <ul>
            {Object.values(errors).map((value, idx) => {
              return <li key={idx}> {value} </li>;
            })}
          </ul>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default AddChef;
