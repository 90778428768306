import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

const SingleSelect = ({ value, label, handleChange, name, options, className, error, errorMess}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} error={error}>
      <InputLabel id="demo-simple-select-error-label" className="label-pos">{label}</InputLabel>
      <Select
        name={name}
        labelId="demo-simple-select-error-label"
        id="demo-simple-select-error"
        value={value}
        onChange={handleChange}
        label={label}
        className={`mt-0 ${className}`}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {options.map((option, idx) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMess}</FormHelperText>
    </FormControl>
  );
};

export default SingleSelect;
