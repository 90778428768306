import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useFetchInterceptor } from '../hooks/useFetchInterceptor';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../redux/authSlice';

const Profile = () => {
  const {user} = useSelector(state => state.user);
  const {token} = useSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchApiInterceptors = useFetchInterceptor();

  const [details, setDetails] = useState({name: '', email: '', phone: ''});

  useEffect(() => {
    setDetails({
      name: user.name,
      email: user.email,
      phone: user.phone
    })

  }, [])

  const handleLogout = async(e) => {
    const result = await fetchApiInterceptors("/auth/logout", token, {
      method: "POST",
      credentials: "include",
    });
    if (result.success) {
      dispatch(setToken(null));
      return navigate("/");
    }
  }
  return (
    <div className='container profile-container'>
      {details.name}
      <br/>
      {details.email}
      <br/>
      {details.phone}

      <Button className='btn logout-btn' onClick={handleLogout}>
        Logout
      </Button>
    </div>
  )
}

export default Profile