import React from 'react'

const ManageOrders = () => {
  return (
    <div className='container managerorder-container'>
        <h1> Coming Soon... </h1>
    </div>
  )
}

export default ManageOrders