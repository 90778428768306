import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Loader from "../containers/Loader";
import Input from "../containers/Input";
import { setToken } from "../redux/authSlice";
import { setIsVerified } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "@mui/material";
import { OtpValidation } from "../utils/validation";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import CommonModal from "../containers/Modal";
import Heading from "../containers/Heading";
// import Timer from "../../../client/src/components/Timer";

const OtpModal = ({ open, closeModal, phone }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [otp, setOTP] = useState(null);
  const [otpError, setOtpError] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "" });
  const [error, setError] = useState(false);

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!otp) {
        setOtpError(true);
        return;
      }
      if (otp && OtpValidation(otp)) {
        setOtpError(true);
        return;
      } else {
        setOtpError(false);
      }
      setLoadings(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/verify`,
        {
          method: "POST",
          body: JSON.stringify({ otp, ...user }),
          credentials: "include", // to store cookie on server
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      const result = await response.json();
      //   setLoadings(false);
      setAlert({ message: result.message, show: true });

      if (result.success) {
        setError(false);
        dispatch(setToken(result.data.token));
        dispatch(setIsVerified(true));
        if (user?.role === 1) {
          navigate("/addchef");
        }
      } else {
        setError(true);
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setAlert({ message: err.message, show: true });
      //   setLoadings(false);
    } finally {
      setLoadings(false);
      // setTimeout(() => {
      //   setAlert({ message: "", show: false });
      // }, 3000);
    }
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };
  return (
    <>
      <CommonModal
        boxClassName="otp_resend_box"
        alert={alert}
        error={error}
        open={open}
        closeModal={closeModal}
        headerComponent={<Heading component={"h5"} content={"OTP"} />}
        headerClassName="otp_box"
      >
        <div style={style}>
          <Input
            type="text"
            id="otp"
            placeholder="Enter OTP"
            value={otp}
            changeHandler={(e) => setOTP(e.target.value)}
          />
          <span className="error">{otpError && "OTP is invalid!"}</span>
          <Button
            type="submit"
            variant="contained"
            className="btn otp_btn"
            onClick={handleOtpSubmit}
            disabled={loadings}
          >
            {loadings ? <Loader /> : "Submit"}
          </Button>
        </div>
      </CommonModal>
      {/* <div className="resend_box">
            <Timer
              // email={email}
              phone={phone}
              setShowAlert={(value) => setAlert({...alert, show: value})}
              setMessage={(value) => setAlert({...alert, message: value})}
              setError={setError}
            />
          </div> */}
    </>
  );
};

export default React.memo(OtpModal);
