import React from 'react'

const AddClients = () => {
  return (
    <div className='container addclient-container'>
        <h1> Coming Soon... </h1>
    </div>
  )
}

export default AddClients