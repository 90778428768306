import { useEffect, useState } from "react";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 920); // Adjust the width as needed
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 920);
      };
  
      window.addEventListener('resize', handleResize);
      
      // Clean up the event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isMobile;
  };