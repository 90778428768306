export const PhoneValidation = (phone) => {
    var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    return !pattern.test(phone);
}

export const OtpValidation = (otp) => {
    var pattern = /^\d{6}$/;
    return !pattern.test(otp);
}

export const TextValidation = (text) => {
    var pattern = /^[a-zA-Z ]{2,}$/;
    return !pattern.test(text)
}


export const EmailValidation = (email) => {
    var pattern = /^[a-zA-Z0-9\W]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/gm; 
    return !pattern.test(email)
}

export const validateAddChefForm = (chefDetails) => {
    const {name, phone, location, workLocation, cuisine, experience, curSalary, level, expSalary} = chefDetails;
    const error = {}
    if(TextValidation(name)){
        error.name = 'Name is invalid!'
    } if(PhoneValidation(phone)){
        error.phone = "Contact Number is invalid!"
    } if(location.length <= 0){
        error.location = 'Location is required!'
    } 
    // if(workLocation.length <= 0){
    //     error.workLocation = 'Select atleast one work location!'
    // }
     if(cuisine.length <= 0){
        error.cuisine = 'Select atleast one cuisine!'
    } if(level.length <= 0){
        error.level = 'Select atleast one level!'
    } if(!(/^\d+(\.\d+)?$/).test(experience)){
        error.experience = 'Only numbers  allowed!'
    } if(!(/^\d+(\.\d+)?$/).test(curSalary)){
        error.curSalary = 'Only numbers are allowed!'
    } if(expSalary && !(/^\d+(\.\d+)?$/).test(expSalary)){
        error.expSalary = 'Only numbers are allowed!'
    }
    
    if(Object.keys(error).length){
        return error;
    }
    return {};
}