import React, { useEffect, useRef, useState } from "react";
import Heading from "../../containers/Heading";
import Input from "../../containers/Input";
import { Alert, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useRedirect } from "../hooks/useRedirect";
import { PhoneValidation, EmailValidation, TextValidation } from "../../utils/validation";
import '../../styles/register.scss';

const Register = () => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [registerUser, setRegisterUser] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show: false, message: ''});
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});

  // const handleRegister = (e) => {
  //   const { name, value } = e.target;
  //   setRegisterUser((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const userData = {
      name: data.get("name"),
      email: data.get("email"),
      phone: data.get("phone"),
    }
    const { email, phone, name } = userData;
    // here validation goes:
    if (TextValidation(name)) {
      setErrors({
        name: "Not valid",
        email: '',
        phone:''
      });
      return;
    } else if (EmailValidation(email)) {
      setErrors({
        name: "",
        phone:'',
        email: "Not valid",
      });
      return;
    } else if (PhoneValidation(phone)) {
      setErrors({
        name: "",
        email: "",
        phone: "Not valid",
      });
      return;
    } else {
      setErrors({
        ...errors,
        phone: "",
        name: "",
        email: "",
      });
    }

    setLoading(true);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/admin/register`,
      {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const result = await response.json();
    setLoading(false);
    setAlert({show: true, message: result.message});
    if (result.success) {
      setError(false);
      // reset fields
      formRef.current.reset();
    } else {
      setError(true);
    }
    setTimeout(() => {
      setAlert({show: false, message: ''});
      if (result.success) navigate("/");
    }, 2000);
  };

  // to redirect if user is already logged in:
  // useRedirect()

  return (
    <div className="container">
      {alert.show && (
        <Alert className="alert-box"
          icon={error ? <ErrorIcon /> : <CheckIcon />}
          variant="filled"
          severity={error ? "error" : "success"}
        >
          {alert.message}
        </Alert>
      )}
      <div className="register_admin">
        <Heading component="h3" className="register" content="Register" />
        <form ref={formRef} method="POST" onSubmit={handleSubmit}>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            classNames={errors.name ? 'border-error' : ''}
            // value={registerUser.name}
            // changeHandler={handleRegister}
          />
          {errors.name && <span className="error">{errors.name}</span>}
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            classNames={errors.email ? 'border-error' : ''}
            // value={registerUser.email}
            // changeHandler={handleRegister}
          />
          {errors.email && <span className="error">{errors.email}</span>}
          <Input
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone"
            classNames={errors.phone ? 'border-error' : ''}
            // value={registerUser.phone}
            // changeHandler={handleRegister}
          />
          {errors.phone && <span className="error">{errors.phone}</span>}

          <Button type="submit" variant="contained" className="btn login_btn"> Sign Up </Button>
        </form>

        <div className="have-acc">
          <p> Already have an account? <span className="btn link-btn"> <NavLink to='/'> <Button variant="text"> Login </Button> </NavLink></span></p>
        </div>
      </div>
    </div>
  );
};

export default Register;
