import React, { useEffect, useMemo, useRef, useState } from "react";
import { setError, setLoading } from "../redux/userSlice";

import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Checkbox } from "@mui/material";
import Heading from "./Heading";
import Loader from "./Loader";
import Input from "./Input";
import Select from "./Select";
import MultiSelect from "./MultiSelect";
import { validateAddChefForm } from "../utils/validation";
import { useFetchInterceptor } from "../hooks/useFetchInterceptor";
import { ChefFormDetails } from "../mocks/constants";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { setChefs, setIsFetched } from "../redux/chefSlice";
import { capitalizeFirst } from "../utils";

const ChefForm = ({
  formClass,
  data = [],
  editForm,
  setEditForm,
  setAlert,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const fetchApiInterceptors = useFetchInterceptor();
  const { user } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const { chefs, error, loading } = useSelector((state) => state.chef);
  const [errors, setErrors] = useState({});
  //   const [alert, setAlert] = useState({ show: false, message: "" });
  const [levelLocationSelect, setLevelLocationSelect] = useState({
    level: "",
    workLocation: [],
    cuisine: [],
    openToPartTime: data.length ? data[0].openToPartTime : false,
  });

  const renderFields = (formDetails) => {
    return formDetails.map((item, idx) => {
      switch (item.type) {
        case "text":
          return (
            <div className={`input_label form_f`} key={idx}>
              <label>{item.label}:</label>
              <div className="input-span">
                <Input
                  type="text"
                  name={item.name}
                  defaultValue={item.defaultValue}
                  value={item.value}
                  placeholder={item.placeholder}
                  changeHandler={item.changeHandler}
                  classNames={
                    Object.keys(errors).includes(item.name)
                      ? "border-error"
                      : ""
                  }
                />
                {Object.keys(errors).length ? (
                  <span className="error">{errors[item.name]}</span>
                ) : null}
              </div>
            </div>
          );
        case "select":
          return (
            <div className={`select_label form_f`} key={idx}>
              <label htmlFor="multiple-select">{item.label}:</label>
              <div className="input-span">
                {item.name === "workLocation" || item.name === "cuisine" ? (
                  <MultiSelect
                    value={item.value || []}
                    error={Object.keys(errors).includes(item.name)}
                    label={item.label}
                    handleChange={item.changeHandler}
                    name={item.name}
                    options={item.options}
                    errorMess={errors[item.name]}
                  />
                ) : (
                  <Select
                    value={item.value}
                    error={Object.keys(errors).includes(item.name)}
                    label={item.label}
                    handleChange={item.changeHandler}
                    name={item.name}
                    options={item.options}
                    errorMess={errors[item.name]}
                  />
                )}
              </div>
            </div>
          );
        case "checkbox":
          return (
            <div className="checkbox_label form_f" key={idx}>
              <label>{item.label}</label>
              <Checkbox
                // defaultValue={item.defaultValue}
                checked={item.value}
                value={item.value}
                name={item.name}
                onChange={item.changeHandler}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          );
      }
    });
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    setLevelLocationSelect({
      ...levelLocationSelect,
      [name]: value,
      openToPartTime: !levelLocationSelect.openToPartTime,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = "";
    let method = "";
    const formData = new FormData(e.currentTarget);
    const chefDetails = {
      name: capitalizeFirst(formData.get("name")),
      phone: formData.get("phone"),
      location: formData.get("location"),
      workLocation: formData.get("workLocation")
        ? formData.get("workLocation").split(",")
        : [],
      experience: formData.get("experience"),
      inJob: true,
      curSalary: formData.get("curSalary"),
      expSalary: formData.get("expSalary"),
      cuisine: formData.get("cuisine").split(","),
      level: formData.get("level"),
      openToPartTime: levelLocationSelect.openToPartTime, //formData.get("openToPartTime") === "on" ? true : false,
    };
    try {
      // validate the form values:
      if (Object.keys(validateAddChefForm(chefDetails)).length) {
        setErrors(validateAddChefForm(chefDetails));
        return;
      }
      dispatch(setLoading(true));
      setErrors({});
      if (editForm) {
        url = `/api/editchef/${data[0]._id}`;
        method = "PUT";
      } else {
        url = `/api/addchef`;
        method = "POST";
      }
      const options = {
        method,
        body: JSON.stringify({ ...chefDetails, user_id: user.user_id }),
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      };
      const result = await fetchApiInterceptors(`${url}`, token, options);
      dispatch(setLoading(false));
      setAlert({
        ...alert,
        show: true,
        message: result?.message || "Please try again!",
      });
      if (result.success) {
        dispatch(setError(false));
        if (editForm) {
          let filteredArr = [...chefs];
          let getIndex = filteredArr.findIndex(
            (value) => value._id === data[0]._id
          );
          filteredArr.splice(getIndex, 1, result.data);
          dispatch(setChefs(filteredArr));
        } else {
          dispatch(setIsFetched(false));
        }
        setLevelLocationSelect({
          level: "",
          workLocation: [],
          cuisine: [],
        });
        if (!editForm) formRef.current.reset();
      } else {
        dispatch(setError(true));
      }
    } catch (err) {
      console.log("error", err);
      dispatch(setError(true));
      dispatch(setLoading(false));
      setAlert({
        ...alert,
        show: true,
        message: `Something went wrong!`,
      });
    } finally {
      dispatch(setLoading(false));
      if (editForm) setEditForm(false);
      setTimeout(() => {
        setAlert({ ...alert, show: false, message: `` });
      }, 2500);
    }
  };

  const formDetails = useMemo(() => {
    return ChefFormDetails(levelLocationSelect, handleSelect, data);
  }, [levelLocationSelect, handleSelect]);

  return (
    <div className={`form ${formClass}`}>
      <form ref={formRef} id="chef_form" onSubmit={handleSubmit}>
        <div className="field_container"> {renderFields(formDetails)}</div>
        <Button
          type="submit"
          className="btn submit_btn"
          // onclick={handleSubmit}
        >
          {loading ? <Loader /> : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default ChefForm;
