import React from "react";

const Input = React.forwardRef(
  (
    {
      type,
      placeholder,
      changeHandler,
      classNames,
      id,
      value,
      onfocus,
      onblur,
      name,
      defaultValue
    },
    ref
  ) => {
    return (
      <input
      defaultValue={defaultValue}
        type={type}
        id={id}
        name={name}
        value={value}
        ref={ref}
        placeholder={placeholder}
        onChange={changeHandler}
        className={classNames}
        onFocus={onfocus}
        onBlur={onblur}
      />
    );
  }
);

export default Input;
