import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useRedirect = () => {
  const { user } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.user_id && token) {
      if (user?.role == "1" || user?.role == "2") {
        return navigate("/chef/add");
      }
    }
    return;
  }, [user, token]);
};
