import React, { useCallback, useState } from "react";
import Input from "../../containers/Input";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { Alert, Box, Button, Typography } from "@mui/material";
import Loader from "../../containers/Loader";
import OtpModal from "../OtpModal";
import { setUser } from "../../redux/userSlice";
import { useDispatch } from "react-redux";
import { PhoneValidation } from "../../utils/validation";
import "../../styles/login.scss";
import "../../styles/alert.scss";
import { NavLink } from "react-router-dom";
import { useRedirect } from "../../hooks/useRedirect";

const Login = () => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "" });
  const [loadings, setLoadings] = useState(false);
  const [error, setError] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const closeModal = useCallback(() => {
    setShowOtpBox(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!phone) {
        setPhoneError(true);
        return;
      }
      if (phone && PhoneValidation(phone)) {
        setPhoneError(true);
        return;
      } else {
        setPhoneError(false);
      }
      setLoadings(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        {
          method: "POST",
          body: JSON.stringify({ phone }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const result = await response.json();
      setAlert({ show: true, message: result.message });
      setLoadings(false);
      if (result.sucess) {
        // otp sent
        setError(false);
        setShowOtpBox(true);
        dispatch(setUser(result.value));
      } else {
        setError(true);
        setShowOtpBox(false);
      }
    } catch (err) {
      setError(true);
      setAlert({ show: true, message: err.message });
    } finally {
      setTimeout(() => {
        setAlert({ show: false, message: "" });
      }, 3000);
    }
  };

  useRedirect();
  return (
    <div className="login">
      {alert.show && (
        <Alert
          className="alert-box"
          variant="filled"
          icon={error ? <ErrorIcon /> : <CheckIcon />}
          severity={error ? "error" : "success"}
        >
          {alert.message}
        </Alert>
      )}
      <div className="otp_login">
        {!showOtpBox ? (
          <>
          <div className="email_phone_box">
            <Typography variant="h5" component="h4">
              Login with OTP
            </Typography>

            <Box className="login_form phone_login">
              <label htmlFor="phone">Phone:</label>
              <Input
                type="text"
                className={phoneError ? "error" : ""}
                id="phone"
                placeholder="Phone"
                value={phone}
                changeHandler={(e) => setPhone(e.target.value)}
              />
              <span className="error">{phoneError && "Phone is invalid!"}</span>
            </Box>

            <Button
              type="submit"
              variant="contained"
              className="btn login_btn"
              onClick={handleSubmit}
              disabled={loadings}
            >
              {loadings ? <Loader /> : "Login"}
            </Button>
          </div>
          <div className="have-acc">
            <p> Not have an account? <span className="btn link-btn"> <NavLink to='/register'> <Button variant="text"> Register </Button> </NavLink></span></p>
          </div>
        </>
        ) : (
          <OtpModal open={showOtpBox} closeModal={closeModal} phone={phone}/>
        )}
      </div>
    </div>
  );
};

export default Login;
