import {configureStore} from '@reduxjs/toolkit';
import userReducer from './userSlice';
import authReducer from './authSlice';
import chefReducer from './chefSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authReducer,
        chef: chefReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat()
})