import { createContext, Suspense, useEffect, useState } from "react";
import "./App.css";
import './styles/alert.scss';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, Routes, useNavigate } from "react-router-dom";
// import Loader from '../../client/src/containers/Loader';
import { useDispatch, useSelector } from "react-redux";
import { Box, CssBaseline } from "@mui/material";
import SideNav from "./components/SideNav";
import Register from "./components/Register";
// import Login from "./components/Login";
import LoginComponent from "./components/Login";

import ErrorPage from "./components/ErrorPage";
import AddChefs from "./components/AddChef";
import ChefLists from './components/ChefList';
import Profile from "./components/Profile";
import ManagerOrders from "./components/ManageOrders";
import AddClients from "./components/Client";
import ClientLists from "./components/Client/ClientList";
import { setIsVerified, setLoading, setUser } from "./redux/userSlice";
import { useFetchInterceptor } from "./hooks/useFetchInterceptor";
import { setToken } from "./redux/authSlice";
import { refreshToken } from "./utils/refreshToken";

export const SelectedMenuContext = createContext();

function App() {
  const theme = createTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchApiInterceptors = useFetchInterceptor();
  const {token} = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  const [selectedMenu, setSelectedMenu] = useState(0);

  // get admin details:
  const getUser = async () => {
    const userDetails = await fetchApiInterceptors("/api/admin", token, {
      credentials: "include",
    });
    if (userDetails?.success) {
      dispatch(setLoading(false));
      dispatch(setUser({ ...user, ...userDetails.user }));
    }
  };

  const verifyRefreshToken = async() => {
    try{
      const {status, token} = await refreshToken();
      if(status === 200){
        dispatch(setToken(token));
        dispatch(setIsVerified(true));
      }
    }catch(err){
      console.log(err);
    }
  }
  
  useEffect(() => {
    verifyRefreshToken();
  }, []);

  useEffect(() => {
    if (token) getUser();
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Suspense fallback={"Loading..."}>
          <SelectedMenuContext.Provider
            value={{ selectedMenu, setSelectedMenu }}
          >
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/" element={<LoginComponent />} />
              <Route element={<SideNav />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/chef">
                  <Route path="add" element={<AddChefs />} />
                  <Route path="list" element={<ChefLists />} />
                </Route>
                <Route path="/client">
                  <Route path="add" element={<AddClients />} />
                  <Route path="list" element={<ClientLists />} />
                </Route>
                <Route path="manage-orders" element={<ManagerOrders />} />
                <Route path="*" element={<ErrorPage />} />
              </Route>
              {/*
              <Route path=":id" element={<AddChef />} />
              </Route>
              </Route> */}
            </Routes>
          </SelectedMenuContext.Provider>
        </Suspense>
      </div>
    </ThemeProvider>
  );
}

export default App;
