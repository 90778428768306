import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Heading from "./Heading";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import { Alert, Button, Divider, styled } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalHeader = styled("div")(() => {});

const ModalBody = styled("div")(() => {});

const ModalFooter = styled("div")(() => {});

const CommonModal = ({
  alert,
  error,
  open,
  closeModal,
  headerComponent,
  headerClassName,
  boxClassName,
  footerComponent,
  children,
}) => {
  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={boxClassName} sx={style}>
        {alert && alert.show && (
          <Alert
            className="alert-box"
            variant="filled"
            icon={error ? <ErrorIcon /> : <CheckIcon />}
            severity={error ? "error" : "success"}
          >
            {alert.message}
          </Alert>
        )}
        {headerComponent && (
          <ModalHeader className={`modal-header ${headerClassName}`}>
            {headerComponent}
          </ModalHeader>
        )}

        <Divider sx={{ marginBottom: "20px" }} />

        <ModalBody className="modal-body">{children}</ModalBody>

        <Divider sx={{ marginTop: "20px" }} />

        {footerComponent && <ModalFooter>{footerComponent}</ModalFooter>}
      </Box>
    </Modal>
  );
};

export default CommonModal;
