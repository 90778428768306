import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: false,
    loading: false,
    chefs: [],
    isFetched: false,
}

export const chefSlice = createSlice({
    name: 'chef',
    initialState,
    reducers: {
        // this is for only chef list:
        setChefs: (state, action) => {
            state.chefs = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setIsFetched: (state, action) => {
            state.isFetched = action.payload
        },
    }
})

export const {setChefs, setLoading, setError, setIsFetched} = chefSlice.actions;
export default chefSlice.reducer;