import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Chip, FormHelperText, OutlinedInput } from "@mui/material";

const MultiSelect = ({ value, label, handleChange, name, options, className, error, errorMess}) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={error}>
      <InputLabel id="demo-multiple-chip-label" className="label-pos">{label}</InputLabel>
      <Select
        multiple
        name={name}
        labelId="demo-multiple-chip-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={handleChange}
        label="Age"
        className={`mt-0 ${className}`}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((values) => (
            <Chip key={values} label={values} />
            ))}
        </Box>
        )}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {options.map((option, idx) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMess}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
